var colors = {
  mainStart: '#3A424B',
  background: '#FFFAF0',
  main: '#E49933',
  darkGreen: '#043C29',
  lightGreen: '#37BFBF',
  inputGreen: '#447153',
  inputPressed: '#9DBBAE',
  disabledButton: '#D3D3D3',
  "static": '#9DB4C9',
  deleteRed: '#E80C02',
  brownish: '#2D3648',
  greenSecondary: '#73BC8C',
  footer: '#F8E5CC',
  errorRed: '#EA3F09',
  errorText: '#D32F2F',
  successGreen: '#1DC71A',
  redText: '#FF3D00',
  darkRed: '#E80C02',
  lightGray: '#CCCCCC',
  mainEnd: 'rgba(255, 255, 255, 1)',
  disabled: 'rgba(0,0,0,0.5)',
  red: '#E80C02',
  white: '#fff',
  accentPattern: 'rgba(165, 203, 240, 0.5)',
  text: '#333333',
  opacityText: 'rgba(30, 30, 30, 0.5)',
  yellow: 'rgba(255, 234, 167, 1)',
  lavender: 'rgba(204, 153, 255, 1)',
  softPink: 'rgba(255, 140, 148, 1)',
  black: '#000000',
  whiteish: '#F8F9FF',
  correct: '#008000',
  error: '#E80C02'
};
export default colors;