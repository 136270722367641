import { initializeApp } from 'firebase/app';
import { initializeAuth, getReactNativePersistence, getAuth } from 'firebase/auth';
import firebaseSetup from './firebaseSetup';
export var initialiseAuthentication = !getReactNativePersistence ? false : function (storage) {
  if (!firebaseSetup.auth) {
    firebaseSetup.auth = initializeAuth(firebaseSetup, {
      persistence: getReactNativePersistence(storage)
    });
  }
  return firebaseSetup.auth;
};