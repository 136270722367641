import _toConsumableArray from "/vercel/path0/packages/webb-page/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
// const object = {
//         "-Nt0eq_ADOvnQfqUWNmB": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":1. Which insect is known to produce food that is consumed by humans?\n* a) Ants\n* b) Honeybee\n* c) Ladybugs\n* d) Butterflies\n* Correct: b)."
//           }
//         ],
//         "-Nt0erBBU-4SDkFXN5cK": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":2. How are bananas botanically classified?\n* a) Citrus\n* b) Berries\n* c) Drupes\n* d) Pomes\n* Correct: b)."
//           }
//         ],
//         "-Nt0es92PvlSpBIPNzM7": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":3. What is the total length of the Great Wall of China?\n* a) 13,170 miles\n* b) 10,000 miles\n* c) 8,850 miles\n* d) 15,500 miles\n* Correct: a)."
//           }
//         ],
//         "-Nt0esN-B6pOGTcVGX8l": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":4. Which planet in our solar system rotates clockwise?\n* a) Mars\n* b) Earth\n* c) Venus\n* d) Jupiter\n* Correct: c)."
//           }
//         ],
//         "-Nt0et0znsznRppE1lDz": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":5. Approximately how many cells does the human body contain?\n* a) 50 trillion\n* b) 37.2 trillion\n* c) 25 trillion\n* d) 60 trillion\n* Correct: b)."
//           }
//         ],
//         "-Nt0etYiwfU-0Uf35-SV": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":6. How many hearts do octopuses have?\n* a) Two\n* b) Three\n* c) Four\n* d) One\n* Correct: b)."
//           }
//         ],
//         "-Nt0euLchRo6tdkcazV9": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":7. Leonardo da Vinci had the ability to:\n* a) Play two musical instruments at once\n* b) Write with one hand and draw with the other simultaneously\n* c) Speak five languages fluently\n* d) Memorize entire books\n* Correct: b)."
//           }
//         ],
//         "-Nt0euvNcFap_z2y9Pjj": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":8. What percentage of the world’s oxygen is produced by the Amazon Rainforest?\n* a) 10%\n* b) 20%\n* c) 30%\n* d) 40%\n* Correct: b)."
//           }
//         ],
//         "-Nt0evmmMvELIkN-sIj-": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":9. How large is the heart of a blue whale?\n* a) As large as a car\n* b) So large a human can swim through its arteries\n* c) Similar in size to an elephant's heart\n* d) Comparable to the size of a small boat\n* Correct: b)."
//           }
//         ],
//         "-Nt0ewQ879LM-pkqhzQK": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":10. What is something kangaroos cannot do?\n* a) Jump\n* b) Walk backwards\n* c) Sleep standing up\n* d) Swim\n* Correct: b)."
//           }
//         ],
//         "-Nt0exDqGmgETHeC3e6u": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":11. During which season can the Eiffel Tower be taller due to thermal expansion?\n* a) Winter\n* b) Summer\n* c) Spring\n* d) Autumn\n* Correct: b)."
//           }
//         ],
//         "-Nt0ey7xAKoXR7yltv2X": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":12. How long did the shortest war in history last?\n* a) 45 minutes\n* b) 38 minutes\n* c) 60 minutes\n* d) 30 minutes\n* Correct: b)."
//           }
//         ],
//         "-Nt0eyk9-JftBfkJQU9O": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":13. The original Olympic Games were held in which location?\n* a) Rome, Italy\n* b) Olympia, Greece\n* c) Athens, Greece\n* d) Sparta, Greece\n* Correct: b)."
//           }
//         ],
//         "-Nt0ezJPU3py7-qlqmkg": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":14. What percentage of the Earth's surface is covered by water?\n* a) 61%\n* b) 71%\n* c) 81%\n* d) 51%\n* Correct: b)."
//           }
//         ],
//         "-Nt0ezzUeO51UCQbDLHR": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":15. How long does it take for light from the sun to reach Earth?\n* a) 5 minutes\n* b) 8 minutes and 20 seconds\n* c) 10 minutes\n* d) 12 minutes\n* Correct: b)."
//           }
//         ],
//         "-Nt0f-WOzruvaTtB2t6E": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":16. How many vertebrae do giraffes have in their necks?\n* a) Seven\n* b) Twelve\n* c) Nine\n* d) Fourteen\n* Correct: a)."
//           }
//         ],
//         "-Nt0f0JK3UPI2L0mzxsO": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":17. What is the temperature of lightning bolts?\n* a) 20,000 kelvins\n* b) 53,540 degrees Fahrenheit\n* c) 30,000 kelvins\n* d) 10,000 kelvins\n* Correct: c)."
//           }
//         ],
//         "-Nt0f0pT9Ro7dtvd5B8i": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":18. Who invented the chocolate chip cookie?\n* a) Mary Jones\n* b) Ruth Graves Wakefield\n* c) Elizabeth Smith\n* d) Anne Brown\n* Correct: b)."
//           }
//         ],
//         "-Nt0f1N2KbHKNjwrN7As": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":19. What is a group of flamingos called?\n* a) A flock\n* b) A flamboyance\n* c) A gathering\n* d) A pack\n* Correct: b)."
//           }
//         ],
//         "-Nt0f1zhdHCjsKfh-tje": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ":20. Who was the first person to reach space?\n* a) Neil Armstrong\n* b) Buzz Aldrin\n* c) Yuri Gagarin\n* d) John Glenn\n* Correct: c)."
//           }
//         ],
//         "-Nt0is_FWwPTPTzOL7yF": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What is the botanical classification of bananas?\n* a) Root\n* b) Berry\n* c) Nut\n* d) Legume\n* Correct: b)."
//           }
//         ],
//         "-Nt0itJskifcyIxRSMPM": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How long is the Great Wall of China approximately?\n* a) 13,170 miles\n* b) 10,000 miles\n* c) 15,500 miles\n* d) 8,850 miles\n* Correct: a)."
//           }
//         ],
//         "-Nt0iuEbDeq21M50qW-L": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": Which direction does Venus rotate?\n* a) Counter-clockwise\n* b) Clockwise\n* c) In a hexagonal pattern\n* d) It doesn't rotate\n* Correct: b)."
//           }
//         ],
//         "-Nt0iuqiZllvQB0Zlcp0": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How many cells does the human body contain?\n* a) Approximately 37.2 million\n* b) Approximately 37.2 billion\n* c) Approximately 37.2 trillion\n* d) Approximately 37.2 quadrillion\n* Correct: c)."
//           }
//         ],
//         "-Nt0iv_meFSGPunREena": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What color is the blood of octopuses?\n* a) Red\n* b) Green\n* c) Blue\n* d) Clear\n* Correct: c)."
//           }
//         ],
//         "-Nt0ivlPBFWt2KHZJUh3": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How many hearts do octopuses have?\n* a) One\n* b) Two\n* c) Three\n* d) Four\n* Correct: c)."
//           }
//         ],
//         "-Nt0iwDjYbLWvDpLWXjx": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What percentage of the world’s oxygen is produced by the Amazon Rainforest?\n* a) 5%\n* b) 10%\n* c) 20%\n* d) 40%\n* Correct: c)."
//           }
//         ],
//         "-Nt0iwmkhpaBUzqwsiTn": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": Which animal cannot walk backwards?\n* a) Elephants\n* b) Kangaroos\n* c) Snakes\n* d) Turtles\n* Correct: b)."
//           }
//         ],
//         "-Nt0ixNADfL-H93AqIPo": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How much taller can the Eiffel Tower get during the summer?\n* a) 6 cm\n* b) 15 cm\n* c) 30 cm\n* d) 45 cm\n* Correct: b)."
//           }
//         ],
//         "-Nt0iy9c1RVqd-EuZZC9": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How long did the shortest war in history last?\n* a) 18 minutes\n* b) 38 minutes\n* c) 58 minutes\n* d) 78 minutes\n* Correct: b)."
//           }
//         ],
//         "-Nt0iyhMp3LbCSYjznyH": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": When were the original Olympic Games first held?\n* a) 776 B.C.\n* b) 850 B.C.\n* c) 920 B.C.\n* d) 1 A.D.\n* Correct: a)."
//           }
//         ],
//         "-Nt0izLIAOSHsuQvVrLf": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What percentage of the Earth's surface is covered by water?\n* a) 61%\n* b) 71%\n* c) 81%\n* d) 91%\n* Correct: b)."
//           }
//         ],
//         "-Nt0j-9CPE2tjfoqUJXE": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How long does the light from the sun take to reach Earth?\n* a) About 5 minutes and 20 seconds\n* b) About 8 minutes and 20 seconds\n* c) About 11 minutes and 20 seconds\n* d) About 15 minutes and 20 seconds\n* Correct: b)."
//           }
//         ],
//         "-Nt0j-dfLBuZavW2R-x7": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How many vertebrae do giraffes have in their necks?\n* a) Five\n* b) Seven\n* c) Nine\n* d) Twelve\n* Correct: b)."
//           }
//         ],
//         "-Nt0j0KtE8OlAslHeoby": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What is the temperature of lightning bolts approximately?\n* a) 10,000 kelvins\n* b) 20,000 kelvins\n* c) 30,000 kelvins\n* d) 40,000 kelvins\n* Correct: c)."
//           }
//         ],
//         "-Nt0j1L2m1XkyC2i5YTi": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": Who invented the chocolate chip cookie and when?\n* a) Ruth Graves Wakefield in 1930\n* b) Betty Crocker in 1942\n* c) Ruth Graves Wakefield in 1938\n* d) Martha Stewart in 1975\n* Correct: c)."
//           }
//         ],
//         "-Nt0j2Hh35r8zc5vD4DS": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What is a group of flamingos called?\n* a) A gaggle\n* b) A herd\n* c) A flamboyance\n* d) A troop\n* Correct: c)."
//           }
//         ],
//         "-Nt0j2sxNE_NixB7EbBA": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": Who was the first person to reach space?\n* a) Neil Armstrong\n* b) Buzz Aldrin\n* c) Yuri Gagarin\n* d) Alan Shepard\n* Correct: c)."
//           }
//         ],
//         "-Nt0j3iN6ugAFmpejUXH": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": How many seconds are there in a common year?\n* a) 28,800,000\n* b) 30,000,000\n* c) 31,536,000\n* d) 33,215,000\n* Correct: c)."
//           }
//         ],
//         "-Nt0j4FRi5xxr_SfNYw8": [
//           {
//             "sentence": "",
//             "testPrio": 2,
//             "value": ": What is the fear of being out of mobile phone contact known as?\n* a) Agoraphobia\n* b) Claustrophobia\n* c) Nomophobia\n* d) Xenophobia\n* Correct: c)."
//           }

// ]
// }

import { sportsQuestions } from './sportsQuestions';
import { generalQuestions } from './generalQuestions';
import { scienceQuestions } from './scienceQuestions';
import { statisticsQuestions } from './statisticsQuestions';
import { historyQuestions } from './historyQuestions';
import { entertainmentQuestions } from './entertainmentQuestions';
var allQuestions = [];
for (var key in sportsQuestions) {
  if (sportsQuestions[key].hasOwnProperty('questions')) {
    for (var questionKey in sportsQuestions[key].questions) {
      // Assuming each 'questions' object holds an array of entries, though in your model it seems like it might not
      // Adjust accordingly if your data structure is different
      allQuestions.push.apply(allQuestions, _toConsumableArray(sportsQuestions[key].questions[questionKey]));
    }
  }
}
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    // Swap elements
    var _ref = [array[j], array[i]];
    array[i] = _ref[0];
    array[j] = _ref[1];
  }
}

// shuffleArray(allQuestions);
// export const triviaGeneralKnowledge = [...Object.values(sportsQuestions)];

// function shuffleArray(array) {
//     for (let i = array.length - 1; i > 0; i--) {
//         const j = Math.floor(Math.random() * (i + 1));
//         [array[i], array[j]] = [array[j], array[i]]; // Swap elements
//     }
// }

var getRandomSubset = function getRandomSubset(sportsQuestions) {
  var allQuestions = [];
  for (var _key in sportsQuestions) {
    if (sportsQuestions[_key].hasOwnProperty('questions')) {
      for (var _questionKey in sportsQuestions[_key].questions) {
        // Assuming each 'questions' object holds an array of entries, though in your model it seems like it might not
        // Adjust accordingly if your data structure is different
        allQuestions.push.apply(allQuestions, _toConsumableArray(sportsQuestions[_key].questions[_questionKey]));
      }
    }
  }
  shuffleArray(allQuestions);
  return allQuestions.slice(0, 20);
};

// function getRandomSubset(originalArray, subsetSize) {
//     let arrayCopy = originalArray.slice();
//     shuffleArray(arrayCopy);
//     return arrayCopy.slice(0, subsetSize);
// }

export var getFromQuery = function getFromQuery(query) {
  if (query === 'general') {
    return getRandomSubset(generalQuestions);
  }
  if (query === 'Sports') {
    return getRandomSubset(sportsQuestions);
  }
  if (query === 'Statistics') {
    return getRandomSubset(statisticsQuestions);
  }
  if (query === 'History') {
    return getRandomSubset(historyQuestions);
  }
  if (query === 'Science') {
    return getRandomSubset(scienceQuestions);
  }
  if (query === 'Entertainment') {
    return getRandomSubset(entertainmentQuestions);
  }
};