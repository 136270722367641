// import Constants from 'expo-constants';
import { initializeApp } from 'firebase/app';
import { initializeAuth, getAuth } from 'firebase/auth';
import { getStorage, ref } from "firebase/storage";
import { initialiseAuthentication } from './RNVars';
export var isReactNative = true;
var isNodeEnvironment;
isNodeEnvironment = typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
var isNextEnv = true && (window === null || window === void 0 ? void 0 : window.next);
var browser = true;
var window = true;
if (!isNodeEnvironment && !browser) {
  isReactNative = true;
} else {
  isReactNative = false;
}
var devConfig = {
  'apiKey': 'AIzaSyDs4p2DsXS9TgLl5neczqLszOXMTCSSvo8',
  'authDomain': 'educ8r-dev.firebaseapp.com',
  'databaseURL': 'https://educ8r-dev-default-rtdb.firebaseio.com',
  'projectId': 'educ8r-dev',
  'storageBucket': 'educ8r-dev.appspot.com',
  'messagingSenderId': '553440822056',
  'appId': '1:553440822056:web:b8fb6765f4bdc1a533061d',
  'measurementId': 'G-4BR1GGRMBT'
};
var liveConfig = {
  'apiKey': 'AIzaSyBIFpAwVIR_8mQalOR35CUGUqsMqydT_Qs',
  'authDomain': 'memory-athlete-fd645.firebaseapp.com',
  'projectId': 'memory-athlete-fd645',
  'databaseURL': 'https://memory-athlete-fd645-default-rtdb.firebaseio.com',
  'storageBucket': 'memory-athlete-fd645.appspot.com',
  'messagingSenderId': '724814291599',
  'appId': '1:724814291599:web:f7e9a574abbaad4b6106fa',
  'measurementId': 'G-Z8800LMJTW'
};
function getEnvVars() {
  //  return liveConfig
  var environment = isReactNative ? 'production' : "production";
  if (environment === 'development') return devConfig;
  if (environment === 'production') return liveConfig;
  return devConfig;
}
var firebaseSetup = initializeApp(getEnvVars());
export var storage = getStorage(firebaseSetup);
export var videosRef = ref;

// export const auth = (storage) => initializeAuth(firebaseSetup, {
//     persistence: getReactNativePersistence(storage)
// });

export var auth = function auth(storage) {
  if (isReactNative && storage) {
    return initialiseAuthentication(storage);
  } else {
    //     if (!firebaseSetup.auth) {
    //         firebaseSetup.auth = initializeAuth(firebaseSetup);
    // }
    return getAuth(firebaseSetup);
  }
};
export default firebaseSetup;