import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import firebase, {auth} from '@studygenius/shared/firebaseSetup';


export const dispatch = useDispatch

export const selector = useSelector;


export const SharedReact = React;

import { initializeApp } from 'firebase/app';
import {initializeAuth, getAuth as fbGetAuth} from 'firebase/auth';
import { getDatabase as getFBDatabase, ref as fbRef, set as fbSet, onValue as fbOnValue, child as fbChild, get as fbGet, off as fbOff, onChildAdded as fbOnChildAdded, onChildChanged as fbOnChildChanged} from 'firebase/database';
import auth from 'firebase/auth';
import {getFunctions as fbGetFunction, httpsCallable as fbHttpsCallable} from 'firebase/functions';

export const initializeFB = initializeApp;
export const initializeFBAuth = initializeAuth;

// export const getFBDatabase = getDatabase
export const ref = fbRef
export const set = fbSet
export const onValue = fbOnValue
export const child = fbChild
export const get = fbGet
export const off = fbOff
export const onChildAdded = fbOnChildAdded
export const onChildChanged = fbOnChildChanged
export const getDatabase = getFBDatabase

export const getFunctions = fbGetFunction;
export const httpsCallable = fbHttpsCallable
export const getAuth = fbGetAuth
// export const {getFunctions, httpsCallable} = functions
// export const {getAuth} = auth;


const authentication = () => false //auth();

export default authentication;

