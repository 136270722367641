import _defineProperty from "/vercel/path0/packages/webb-page/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/vercel/path0/packages/webb-page/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { setCurrentIteration } from '@studygenius/shared/Requests';
import { addUsedResource, createGameCharacters, setHasGottenUserPoints } from '@studygenius/shared/Requests';
export function convertToNumber(lett) {
  if (!lett) return false;
  if (lett.includes('A+')) return 0;
  return lett.charCodeAt(0) - 64;
}
export function convertToGrade(num) {
  if (num === 0) return 'A+';
  if (!num) return false;
  return String.fromCharCode(num + 64);
}
export var moreThen3MinSinceCreated = function moreThen3MinSinceCreated(createdAt) {
  if (!createdAt) {
    return false;
  }
  return !!(createdAt > Date.now() - 1000 * 60 * 7);
};
function shuffleArray(array) {
  return array.sort(function () {
    return Math.random() - 0.5;
  });
}
export var usedResource = function usedResource(userProfile, highlightedElement, onResourcesOut, dispatchUpdatedResource) {
  var _userProfile$activeSu;
  var disgardResourceRemoval = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  if (userProfile.isAdmin) return false;

  // return onResourcesOut();
  if (((_userProfile$activeSu = userProfile.activeSubscription) === null || _userProfile$activeSu === void 0 ? void 0 : _userProfile$activeSu.coins) <= 0 || !userProfile.activeSubscription || !userProfile.activeSubscription.coins) {
    // if(!userProfile.hasCreditCard) {

    onResourcesOut();
    return true;
  }
  if (disgardResourceRemoval) return false;
  addUsedResource(userProfile, highlightedElement, dispatchUpdatedResource);
  return false;
};

// function generateRandomID(length) {
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let result = '';
//     const charactersLength = characters.length;

//     for (let i = 0; i < length; i++) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }

//     return result;
// }
export var convertUrl = function convertUrl(originalUrl) {
  try {
    return originalUrl;
    if (!originalUrl || !originalUrl.includes) return false;
    if (originalUrl.includes("https://storage.googleapis.com/".concat(bucketName, "/"))) {
      return originalUrl;
    }
    var bucketName = 'memory-athlete-fd645.appspot.com';
    var baseUrl = 'https://firebasestorage.googleapis.com/v0/b';
    var _originalUrl$split = originalUrl.split('?'),
      _originalUrl$split2 = _slicedToArray(_originalUrl$split, 2),
      url = _originalUrl$split2[0],
      queryParams = _originalUrl$split2[1];
    var filePath = url.replace("https://storage.googleapis.com/".concat(bucketName, "/"), '').split('/').join('%2F');
    var newUrl = "".concat(baseUrl, "/").concat(bucketName, "/o/").concat(filePath, "?alt=media&").concat(queryParams);
    return newUrl;
  } catch (e) {
    return false;
  }
};
export var removeCorrectAnswerText = function removeCorrectAnswerText(string) {
  return string.replace(/^[a-z][).]\s?/gmi, ''); //?.replace(/(^\[.*\]\s*)|(\s*\[.*\]$)|(^\(.*\)\s*)|(\s*\(.*\)$)/gi, '');
};

export var getCorrectOption = function getCorrectOption(value) {
  var str = value;
  var correctAnswer = (str === null || str === void 0 ? void 0 : str.match(/Correct: (\w)/)) && (str === null || str === void 0 ? void 0 : str.match(/Correct: (\w)/).length) > 1 && (str === null || str === void 0 ? void 0 : str.match(/Correct: (\w)/)[1]);
  if (!correctAnswer) {
    var regex = /\* ([^\*]+?)\(R\)/;
    var match = value.match(regex);
    if (!match || !match.length) {
      var _regex = /¤ ([^¤]+?)½½/;
      match = value.match(_regex);
      if (!match || !match.length) {
        return false;
      }
    }
    var correct = match[1];
    return correct;
  }
  var option = str.split('\*').find(function (s) {
    return s.includes(correctAnswer + ')');
  });
  if (!(option !== null && option !== void 0 && option.trim())) return false;
  return option.trim();
};

// export const getCorrectOptionIndex = (value) => {
//     const str = value;
//     const optionRegex = /\* ([^\*]+?)(?:\(R\)|½½)?/g; // Regex to get all options
//     let options = [...str.matchAll(optionRegex)].map(match => match[1].trim()); // Extract all options and trim whitespace
//     let correctAnswer = str?.match(/Correct: (\w)/) ? str.match(/Correct: (\w)/)[1] : null;

//     if (!correctAnswer) {
//         // Check for patterns indicating correct answer
//         const regex = /\* ([^\*]+?)\(R\)/;
//         let match = value.match(regex);
//         if (match && match.length) {
//             correctAnswer = match[1].trim();
//         } else {
//             const regex = /¤ ([^¤]+?)½½/;
//             match = value.match(regex);
//             if (match && match.length) {
//                 correctAnswer = match[1].trim();
//             }
//         }
//     }

//     if (!correctAnswer) return false;

//     // Find index of correct option
//     // Assuming correctAnswer is a letter (A, B, C, D, ...)
//     let correctIndex = options.findIndex(option => option.startsWith(correctAnswer + ')'));
//     return correctIndex !== -1 ? correctIndex : false;
// };

export var ranks = [{
  points: 0,
  name: 'Amateur'
}, {
  points: 1000,
  name: 'Junior student'
}, {
  points: 3000,
  name: 'Student'
}, {
  points: 6000,
  name: 'Senior student'
}, {
  points: 10000,
  name: 'Fact entusiast'
}, {
  points: 14000,
  name: 'Knowledgeable'
}, {
  points: 21000,
  name: 'Fact collector'
}, {
  points: 28000,
  name: 'Expert'
}, {
  points: 40000,
  name: 'Junior professor'
}, {
  points: 50000,
  name: 'Professor'
}, {
  points: 65000,
  name: 'Senior professor'
}, {
  points: 12000,
  name: 'Master of knowledge'
}, {
  points: 1000000,
  name: 'Amateur'
}];
export function getUserRankAndPoints(userPoints) {
  var lastRank = {
    points: 0,
    name: 'Unranked'
  }; // Default to Unranked with 0 points
  var nextRank = null;
  for (var i = 0; i < ranks.length; i++) {
    if (userPoints < ranks[i].points) {
      nextRank = ranks[i];
      break;
    }
    lastRank = ranks[i];
  }
  var userRankName = lastRank.name;
  var pointsOverLast = userPoints - lastRank.points;
  var pointsToNext = nextRank ? nextRank.points - lastRank.points : '';
  return {
    rankName: userRankName,
    pointsOverLast: pointsOverLast,
    pointsToNext: pointsToNext,
    lastRankPoints: lastRank.points
  };
}
//     if(points < 1000) return 'Amateur';
//     if(points < 3000) return  'Student';
//     if (points < 6000) return 'Senior Studen';

//     return 'Expert';
// }

export var getCorrectOptionIndex = function getCorrectOptionIndex(questionWithOptions) {
  // return 1
  // First, use a regular expression to match the correct answer identifier after "Correct:" (e.g., "a", "b", "c", "d")
  var correctAnswerMatch = questionWithOptions === null || questionWithOptions === void 0 ? void 0 : questionWithOptions.match(/\*\s*Correct:\s*([abcd])\)?/i); //questionWithOptions?.match(/\*\s*Correct:\s*([abcd])\)?/i);    
  if (!correctAnswerMatch) {
    // throw new Error('Correct answer not found');
    return 1;
  }

  // Extract the corresponding letter of the correct answer (e.g., "a", "b", "c", "d")
  var correctAnswerLetter = correctAnswerMatch[1];

  // Map the letters to the corresponding index
  var letterToIndexMap = {
    'a': 0,
    'b': 1,
    'c': 2,
    'd': 3
  };

  // Get the index of the correct answer using the letterToIndexMap
  var correctAnswerIndex = letterToIndexMap[correctAnswerLetter.toLowerCase()];
  return correctAnswerIndex;
};
export var getAsArray = function getAsArray(myVar) {
  if (!myVar) {
    return false;
  }
  if (Array.isArray(myVar)) {
    return myVar;
  } else if (typeof myVar === 'object') {
    var values = Object.values(myVar);
    return values;
  }
};
export function generateRandomID(length) {
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export var filterQuestion = function filterQuestion(questionValue) {
  if (!(questionValue !== null && questionValue !== void 0 && questionValue.value)) return false;
  var parts = questionValue === null || questionValue === void 0 ? void 0 : questionValue.value.split('*');
  if (!parts || !parts.length) {
    parts = questionValue.value.split('¤');
  }
  if (!parts || parts.length <= 2) {
    parts = questionValue.value.split(/(?=\s[a-z]\))/);
  }
  var question = parts[0];
  var correct = getCorrectOption(questionValue.value);
  if (!question || !question.length || question.length < 4) {
    var regex = new RegExp(correct, 'ig');
    question = questionValue.sentence.replace(regex, '****');
  }
  if (!correct || !question) {
    return false;
  }
  return true;
};
export var filterQuestions = function filterQuestions(questions) {
  return questions.filter(function (questionValue) {
    return filterQuestion(questionValue);
  });
};
export var orderQuestionsInFacts = function orderQuestionsInFacts(props, userFacts, setNewIteration) {
  var returnIfNone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var userId = arguments.length > 4 ? arguments[4] : undefined;
  var dispatchHasGottenUserPoints = arguments.length > 5 ? arguments[5] : undefined;
  var factInUser = userFacts[props.id];
  var characters = [{
    points: 0
  }, {
    points: 0
  }, {
    points: 0
  }, {
    points: 0
  }];
  if (!factInUser.gameCharacters) {
    createGameCharacters(userId, props.id, props.groupName, characters);
  }
  if (factInUser.currentIteration) {
    var filteredQuestions = filterQuestions(getAsArray(factInUser.currentIteration));

    // const unfinishedQuestions = filterQuestion(getAsArray(factInUser.currentIteration))

    var unfinishedQuestions = filteredQuestions.filter(function (question) {
      if (question.isCompleted) {
        return false;
      }
      return true;
    });
    if ((unfinishedQuestions === null || unfinishedQuestions === void 0 ? void 0 : unfinishedQuestions.length) > 0) {
      return factInUser.currentIteration;
    }
  }
  if (returnIfNone) return false;
  if (!props.questions) return false;
  var questionsArray = Array.isArray(props.questions) ? props.questions : Object.values(props.questions);
  var questions = questionsArray === null || questionsArray === void 0 ? void 0 : questionsArray.map(function (question, factIndex) {
    var newQuestion = question.map(function (q, questionIndex) {
      return _objectSpread(_objectSpread({}, q), {}, {
        factIndex: factIndex,
        questionIndex: questionIndex
      });
    });
    return newQuestion;
  });
  var flattened = questions.flat();
  var sortedArray = shuffleArray(flattened);
  var correctedArray = filterQuestions(sortedArray);
  var arrayWithIterationIndex = correctedArray.map(function (question, iterationIndex) {
    return _objectSpread(_objectSpread({}, question), {}, {
      iterationIndex: iterationIndex
    });
  });
  dispatchHasGottenUserPoints();
  setCurrentIteration(userId, props.id, props.groupName, arrayWithIterationIndex);
  createGameCharacters(userId, props.id, props.groupName, characters);
  // createGameCharacters(userId,  props.id, props.groupName, characters)

  setNewIteration(props.groupName, props.id, arrayWithIterationIndex, characters);
  setHasGottenUserPoints(userId, props.groupName, props.id, false);
  if ((arrayWithIterationIndex === null || arrayWithIterationIndex === void 0 ? void 0 : arrayWithIterationIndex.length) > 0) {
    return arrayWithIterationIndex;
  }
  return false;
};